import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
import { getHeaderForUploadFile } from "./contentService";

const { AUTH0_ID_TOKEN, APP_USER_AUTH0_ID_TOKEN } = keyTypeConstants;
const {
  METHOD_TYPE: { POST, GET, PUT, PATCH, DELETE },
  API_END_POINT: {
    LOGIN_WITH_GOOGLE,
    SEND_INVITATION,
    GET_TENANT_MEMBERS,
    REMOVE_CHATBOT_ACCESS,
    GET_TENANT,
    WAIT_LIST_USER,
    UPDATE_TENANT,
    EDIT_USER,
    VALIDATE_INVITE,
    APP_ACCESS,
    SAVE_USER,
    EMAIL_VERIFICATION,
    CHANGE_PASSWORD,
    CHANGE_USER_PASSWORD,
    INVITE_TENANT,
    GET_INVITE_DETAILS,
    GET_INVITED_USERS,
    REMOVE_USER_ACCESS,
    GET_TENANT_DETAILS,
    CHANGE_USER_STATUS,
    VALIDATE_PLATFORM_INVITE,
    SEND_ADMIN_INVITE,
    SET_USER_VISITED_TOUR,
    MODELS,
    GET_MODELS,
  },
} = httpConstants;

export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export function getAppUsersHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      APP_USER_AUTH0_ID_TOKEN
    )}`,
  };
}

export default class UserService {
  baseUrl = process.env.REACT_APP_TENANT_MICROSERVICE_URL;
  localUrl = "http://localhost:3002";

  saveUser = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + LOGIN_WITH_GOOGLE, requestData)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  saveGoogleUser = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + SAVE_USER, requestData)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  sendInvite = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + SEND_INVITATION,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  sendInviteUser = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + WAIT_LIST_USER, requestData)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  editUserDetails = async (requestData) => {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_TENANT_MICROSERVICE_URL + `${EDIT_USER}`, {
        method: POST,
        headers: getHeaderForUploadFile(),
        body: requestData,
      })
        .then(async (data) => {
          try {
            const res = await data.json();
            if (!res.success) {
              if (Number(res.responseCode) === 401) {
                return reject();
              }
              const error =
                res.responseCode === 500
                  ? res
                  : res?.message || data.statusText;
              return reject(error);
            }
            return resolve(res.responseData);
          } catch (err) {
            reject(err);
          }
        })
        .catch(reject);
    });
  };

  getTenantMembers = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${GET_TENANT_MEMBERS}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  removeChatbotAccess = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + REMOVE_CHATBOT_ACCESS,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  checkTenantExistence = async ({ email }) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${GET_TENANT}?email=${email}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateTenantDetails = async (requestData, tenantId) => {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_TENANT_MICROSERVICE_URL +
        `${UPDATE_TENANT}?tenantId=${tenantId}`,
        {
          method: POST,
          body: requestData,
          headers: getHeaderForUploadFile(),
        }
      )
        .then(async (data) => {
          try {
            const res = await data.json();
            if (!res.success) {
              if (Number(res.responseCode) === 401) {
                return reject();
              }
              const error =
                res.responseCode === 500
                  ? res
                  : res?.message || data.statusText;
              return reject(error);
            }
            return resolve(res.responseData);
          } catch (err) {
            reject(err);
          }
        })
        .catch(reject);
    });
  };

  validateInvite = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + VALIDATE_INVITE,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  verifyAppAccess = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + APP_ACCESS,
        requestData,
        getAppUsersHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  changePassword(request, token) {
    return new Promise((resolve, reject) => {
      const headers = getHeaders();
      if (token) headers.Authorization = `Bearer ${token}`;
      httpService(PUT, this.baseUrl + CHANGE_PASSWORD, request, headers)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  changeUserPassword(request, token) {
    return new Promise((resolve, reject) => {
      const headers = getHeaders();
      if (token) headers.Authorization = `Bearer ${token}`;
      httpService(PUT, this.baseUrl + CHANGE_USER_PASSWORD, request, headers)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  sendVerifyEmail = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + EMAIL_VERIFICATION,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  inviteTenant = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + INVITE_TENANT, requestData, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  editInvitedUser = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PATCH,
        `${this.baseUrl}/user/${requestData.id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getInviteDetails = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + GET_INVITE_DETAILS + "?" + query,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getInvitedUsers = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + GET_INVITED_USERS + "?" + query,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  removeTenantAccess = async (requestData, tenantId) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + REMOVE_USER_ACCESS + `?tenantId=${tenantId}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  changeUserStatus = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + CHANGE_USER_STATUS,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getTenantDetails = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + GET_TENANT_DETAILS + "/" + id,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  validatePlatformInvite = async (data) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + VALIDATE_PLATFORM_INVITE,
        data,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  sendAdminInvite = async () => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + SEND_ADMIN_INVITE, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  setUserHasVisitedTour = async (id, data) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        `${this.baseUrl}${SET_USER_VISITED_TOUR}/${id}/guided-tour`,
        data,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateModel = async (modelId, data) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + MODELS + `/${modelId}`,
        data,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  createModel = async (data) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + MODELS, data, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  deleteModel = async (modelId, queryData) => {
    const { tenantId, ...otherQueryParams } = queryData; // Extract tenantId from queryData
    const query = new URLSearchParams(otherQueryParams).toString(); // Exclude tenantId from the query string
    return new Promise((resolve, reject) => {
        httpService(
            DELETE,
            this.baseUrl + MODELS + `/${modelId}?${query}`, // URL with remaining query params
            { tenantId }, // Send tenantId in the payload
            getHeaders()
        )
        .then((res) => {
            if (!res.success || res.responseCode !== 200) return reject(res);
            return resolve(res?.responseData);
        })
        .catch(reject);
    });
};
  getNewModels = async (requestedData) => {
    const { type, ...queryParams } = requestedData;
    const query = new URLSearchParams(queryParams).toString();
    const url = `${this.baseUrl}${MODELS}/${type}/properties?${query}`;

    return new Promise((resolve, reject) => {
      httpService(GET, url, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) {
            return reject(res);
          }
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getModels = async(requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + GET_MODELS + `?${query}`, {}, getHeaders())
      .then((res) => {
        if (!res.success || res.responseCode !== 200) {
          return reject(res);
        }
        return resolve(res?.responseData);
      })
      .catch(reject);
    });
  }
}
