import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const {
  METHOD_TYPE: { POST, GET , PUT },
  API_END_POINT_V2: { IMPORT },
  API_END_POINT: {
    KNOWLEDGE_BASE_IMPORT_BY_API,
    KB_AUTOMATED_REASONING,
    KB_VARIABLES,
    KB_RULES,
    PLAYGROUND,
    PLAYGROUND_HISTORY,
    ADD_CUSTOM_RULES_OR_VARIABLES,
    EDIT_KB_VARIABLES,
    EDIT_KB_RULES,
  },
} = httpConstants;

const { AUTH0_ID_TOKEN } = keyTypeConstants;

export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class KBImportService {
  baseUrl = process.env.REACT_APP_IMPORT_MICROSERVICE;
  importUrl = process.env.REACT_APP_IMPORT_MICROSERVICE;
  contentMicroServiceUrl = process.env.REACT_APP_CONTENT_MICROSERVICE_URL;
  localUrl = "http://localhost:3010";

  createImport = async (reqQuery, requestData) => {
    const query = new URLSearchParams(reqQuery).toString();
    const res = await httpService(
      POST,
      `${this.baseUrl}${IMPORT}?${query}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  kbImportByAPI = async (reqQuery, requestData) => {
    const query = new URLSearchParams(reqQuery).toString();
    const res = await httpService(
      POST,
      `${this.baseUrl}${KNOWLEDGE_BASE_IMPORT_BY_API}?${query}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  updateAutomatedReasoning = async (requestData) => {
    const res = await httpService(
      POST,
      `${this.importUrl}${KB_AUTOMATED_REASONING}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  addCustomRulesVarible = async (requestData) => {
    const res = await httpService(
      POST,
      `${this.importUrl}${ADD_CUSTOM_RULES_OR_VARIABLES}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  editKbVariables = async (requestData) => {
    const res = await httpService(
      PUT,
      `${this.importUrl}${EDIT_KB_VARIABLES}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  editKbRules = async (requestData) => {
    
    const res = await httpService(
      PUT,
      `${this.importUrl}${EDIT_KB_RULES}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  getKbVariables = async (knowledgeBaseId, query) => {
    const queryData = new URLSearchParams(query).toString();
    const res = await httpService(
      GET,
      `${this.importUrl}${KB_VARIABLES}/${knowledgeBaseId}?${queryData}`,
      {},
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  getKbRules = async (knowledgeBaseId, query) => {
    const queryData = new URLSearchParams(query).toString();
    const url =
      `${this.importUrl}${KB_RULES}/${knowledgeBaseId}?${queryData}`.replace(
        /([^:]\/)\/+/g,
        "$1"
      );
    const res = await httpService(GET, url, {}, getHeaders());
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  playgroundQuery = async (requestData) => {
    const res = await httpService(
      POST,
      `${this.importUrl}${PLAYGROUND}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  getPlaygroundHistory = async (knowledgeBaseId, query) => {
    const queryData = new URLSearchParams(query).toString();
    const res = await httpService(
      GET,
      `${this.contentMicroServiceUrl}${PLAYGROUND_HISTORY}/${knowledgeBaseId}?${queryData}`,
      {},
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };
}
