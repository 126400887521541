import { combineReducers } from "redux";
import user from "./user";
import invitedUser from "./invitedUser";
import sidebarComponent from "./manageSidebar";
import verifiedEmailComponent from "./verifyEmail";
import KBImportsComponent from "./kbImports";
import ConfigureBotComponent from "./configureBot";
import creditsReducer from "./credits";
import manageWorker from "../modules/manageWorkers/reducer"

export default combineReducers({
  user,
  invitedUser,
  sidebar: sidebarComponent,
  verified: verifiedEmailComponent,
  kbImports: KBImportsComponent,
  configureBot: ConfigureBotComponent,
  credits:creditsReducer,
  worker: manageWorker
});
