/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */

export const httpConstants = {
  METHOD_TYPE: {
    POST: "POST",
    PUT: "PUT",
    GET: "GET",
    DELETE: "DELETE",
    PATCH: "PATCH",
  },
  CONTENT_TYPE: {
    APPLICATION_JSON: "application/json",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
    IMAGE_PNG: "image/png",
  },
  DEVICE_TYPE: {
    WEB: "web",
  },
  API_END_POINT: {
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    MANAGE_APPS: "/manage-app",
    GET_KNOWLEDGE_BASES: "/knowledge-bases",
    GET_KNOWLEDGE_BASE_BY_ID: "/knowledge-base",
    KNOWLEDGE_BASE_IMPORT_FILE: "/knowledge-base-import",
    IMPORT_KNOWLEDGE_BASE_IMPORT_FILE: "/v2/import",
    IMPORT_ESIMATE: "/v2/import-estimate",
    IMPORT_INIT: "/v2/import-init",
    KNOWLEDGE_BASE: "/knowledge-base",
    KNOWLEDGE_BASE_IMPORT: "/knowledge-base-import",
    LOGIN_WITH_GOOGLE: "/user",
    SAVE_USER: "/save-user",
    APPS: "/apps",
    QUERY: "/query",
    CHATBOT: "/chatbot",
    QUERY_HISTORY: "/query-history",
    DELETE_APP: "/delete-app",
    CONVERSATIONS: "/conversations",
    CLEAR_CONVERSATIONS: "/clear-conversations",
    USER_CHAT: "/user-chat",
    DELETE_CONVERSATION: "/delete-conversation",
    VERIFY_INVITE: "/verify-invite",
    SEND_INVITATION: "/invite",
    GET_TENANT_MEMBERS: "/tenant-members",
    SIGN_UP_USER: "/sign-up-user",
    SIGN_IN_USER: "/sign-in-user",
    REMOVE_CHATBOT_ACCESS: "/remove-chatbot-access",
    IMPORT_KNOWLEDGE_BASE: "/import-knowledge-base",
    BOT_ICON: "/bot-icon",
    BOT_LOGO: "/bot-logo",
    GET_API_KEY: "/get-api-key",
    GET_PLANS: "/plans",
    GET_SUBSCRIPTION: "/subscription",
    CANCEL_SUBSCRITPION: "/cancel-subscription",
    UPGRADE_DEGRADE: "/upgrade-degrade-plan",
    CREDIT_USAGE: "/credit-usage",
    GET_AUTHORIZATION_URL: "/authorization-url",
    GET_TENANT: "/tenant",
    WAIT_LIST_USER: "/waitlist-user",
    UPDATE_TENANT: "/update-tenant",
    SET_USER_VISITED_TOUR: "/tenants",
    VALIDATE_INVITE: "/validate-invite",
    VALIDATE_PLATFORM_INVITE: "/admin/validate-invite",
    APP_ACCESS: "/app-access",
    SUGGESTIONS: "/suggestions",
    SAVE_SUGGESTIONS: "/save-suggestions",
    LIKE_DISLIKE_QUERY: "/like-dislike-query",
    EMAIL_VERIFICATION: "/email-verification",
    VIEW_EXTRACTED_DATA: "/view-extracted-data",
    SUMMARISED_IMPORT: "/summarised-import",
    CHANGE_PASSWORD: "/change-password",
    CHANGE_USER_PASSWORD: "/change-user-password",
    AUTH_PROFILE_BY_EMAIL: "/auth-profile-by-email",
    QUERY_FLOW: "/query-flow",
    FLOW: "/flow",
    FLOWS: "/flows",
    CHAT_USER: "/chat-user",
    INVOICE: "/invoice",
    QUERY_SESSION: "/query-session",
    INVITE_TENANT: "/invite-tenant",
    GET_INVITE_DETAILS: "/get-invite-details",
    GET_INVITED_USERS: "/get-invited-users",
    CHECK_CSV_EXIST: "/check-csv-exist",
    GET_SCHEMA_DETAILS: "/knowledge-base-schema-details",
    REMOVE_USER_ACCESS: "/remove-tenant-access",
    GET_TENANT_DETAILS: "/get-tenant",
    BOT_PERFORMANCE: "/bot-performance",
    REMOVE_SCHEMA: "/schema",
    GET_QUERY_HISTORY: "/tenant-query-history",
    RUN_PERFORMANCE_TEST: "/run-performance-test",
    REVERT_APP_SETTINGS: "/revert-app-settings",
    GET_PERFORMANCE_TEST: "/tenant-bot-performance",
    GET_GOLDEN_QUESTION: "/golden-question-apps",
    ACCOUNT_COMPLETION: "/account-completion",
    REGENERATE_API_KEY: "/api/regenerate-api-key",
    CHANGE_USER_STATUS: "/change-user-access-status",
    ACTION_STEPS: "/upload-file",
    GOOGLE_SEARCH: "/google-search",
    SEARCH_LLM: "/search-by-llm",
    GET_QUERY_INFO: "/query-info",
    AGENT: "/agent",
    AGENTS: "/agents",
    WORKFLOW: "/workflow",
    WORKFLOWS: "/workflows",
    SUMMARIZE_DOC: "/summarize-doc",
    SUMMARIZE_DOC_AND_SAVE: "/summarize-doc-and-save-to-db",
    SUMMARIZE_MODEL: "/models",
    GET_MODELS: "/models",
    MODEL: "/model",
    SAVE_MODEL: "/save-model",
    UPDATE_MODEL: "/model",
    UPDATE_CONTENT: "/edit-import-content",
    REVERT_VERSION: "/revert-import-version",
    CREATE_PIPEDRIVE_TENANT: "/create-pipedrive-tenant-details",
    UPDATE_PIPEDRIVE: "/pipedrive-tenant-details",
    HUBSPOT_TENANT: "/hubspot/tenant",
    PAYMENT_LINK: "/payment-link",
    ADDONS: "/addons",
    RETRIEVAL_TESTING: "/v2/retrieval-testing",
    RETRIEVAL_HISTORY: "/v2/retrieval-history",
    REVIEW_DATA_IN_CHUNKS: "/v2/review-data",
    CREATE_UPDATE_CHUNK: "/v2/knowledge-base-import/chunk",
    UPDATE_META_DATA: "/v2/knowledge-base-import",
    ANALYTICS: "/v2/app-analytics",
    QUERYDETAIL: "/v2/query-details",
    ORCHESTRATOR_FLOWS: "/api/v1/internal/flows",
    ORCHESTRATOR_FLOWS_COUNT: "/api/v1/flows/count",
    CONNECTIONS_LIST: "/api/v1/app-connections",
    REPORTS: "/app-reports",
    REPORTS_FILTER: "/app-reports/filter",
    DELETE_CHUNK: "/v2/knowledge-base-import/chunk",
    FEEDBACK_DATA: "/query-feedback-status",
    CONNECTIONS: "/v2/connections",
    CONNECTION: "/v2/connection",
    SEND_ADMIN_INVITE: "/admin/invite-user",
    AGENT_EXECUTE: "/api/agent/execute",
    CONNECTIONS_REQUIRED_CONFIGURATIONS:
      "/v2/connections-required-configurations",
    AUTH_TOKEN: "/api/v1/authentication/token",
    APP_CONNECTIONS: "/api/v1/app-connections",
    WORKER_TEMPLETES: "/worker-templates",
    WORKER_TEMPLETE: "/worker-template",
    WORKER_RECORDS: "/worker-records",
    WORKERS: "/workers",
    TRIGGER_WORKER: "/worker-trigger",
    WORKERS_UPLOAD: "/workers/upload",
    WORKER_REPORTS: "/worker-reports",
    WORKER_RECORDS_LOGS: "/worker-record-logs",
    RETRY_FAILED_ACTIVITY: "/worker-trigger/retry",
    WORKER_FEEDBACK: "/worker-record-feedback",
    WORKER_TEMPLATE_CATEGORIES: "/worker-template-categories",
    KNOWLEDGE_BASE_IMPORT_BY_API: "/knowledge-base-import-by-api",
    KB_AUTOMATED_REASONING: "/automated-reasoning",
    ESTIMATE_AUTOMATED_REASONING_COST: "/estimate-automated-reasoning-cost",
    MODELS: "/models",
    KB_VARIABLES: "/kb-variables",
    KB_RULES: "/kb-rules",
    PLAYGROUND:"/playground",
    PLAYGROUND_HISTORY:"/playground-history",
    ADD_CUSTOM_RULES_OR_VARIABLES: "/add-custom-rules-or-variables",
    EDIT_KB_VARIABLES: "/variables",
    EDIT_KB_RULES: "/rules",
    HANDLE_OUTPUT_ACTION: "/handle-output-action",
    AGENT_CONNECTION: "/agent-connections",
    WORKER_FLOW_IMPORT: "/workers/flow-import",
    WORKER_AGENTS: "/workers/agent",
    TENANT_USERS :"/users/agent-access",
    AGENT_ACCESS: "/agent-access",
    USER_ACCESS: "/user-access",
    GET_CLIENT_ID: "/api/v1/auth-credentials/client-ids?edition=ce",
    ACTIVE_PIECE:"/api/v1/pieces/",
    V2: {
      INFO_SCHEMAS: "/v2/information-schemas",
      INFO_SCHEMA: "/v2/information-schema",
      INFO_SCHEMA_RUN: "/v2/information-schema/run",
      INFO_SCHEMA_COLUMN: "/v2/information-schema/column",
    },
  },
  API_END_POINT_V2: {
    IMPORT: "/v2/import",
    AGENTS: "/v2/agents",
    AGENT: "/v2/agent",
    TOOLS: "/v2/tools",
    TOOL: "/v2/tool",
    LOGS: "/v2/agent-logs",
  },
};

export const keyTypeConstants = {
  USER: "user",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  AUTH0_ID_TOKEN: "AUTH0_ID_TOKEN",
  APP_USER_AUTH0_ID_TOKEN: "APP_USER_AUTH0_ID_TOKEN",
  INVITATION_DATA: "invitation-data",
  USER_PARAMS: "user_params",
  APP_ID: "APP_ID",
  REFERRED: "REFERRED",
  KNOWLEDGE_BASE_DETAILS: "KNOWLEDGE_BASE_DETAILS",
  KNOWLEDGE_BASE_ID: "KNOWLEDGE_BASE_ID",
  SHEETS_ID: "SHEETS_ID",
  CREDENTIALS_ID: "CREDENTIALS_ID",
  DOCS_ID: "DOCS_ID",
  IMPORT_TYPE: "IMPORT_TYPE",
  GMAIL_QUERY: "GMAIL_QUERY",
  GMAIL_MAX_RESULTS: "GMAIL_MAX_RESULTS",
  NUMBEROFRESULTS: "NUMBEROFRESULTS",
  STARTDATE: "STARTDATE",
  CURRENTPAGE: "CURRENTPAGE",
  PLANTYPE: "PLANTYPE",
  CHATUSERINFOPUBLIC: "CHATUSERINFOPUBLIC",
  AGENTINFOPUBLIC: "AGENTINFOPUBLIC",
  CHATUSERINFOPRIVATE: "CHATUSERINFOPRIVATE",
  CHATWIDGETUSERINFO: "CHATWIDGETUSERINFO",
  INVITE_TOKEN: "INVITE_TOKEN",
  APP_STATE: "APP_STATE",
  AGENT_STATE: "AGENT_STATE",
  WORKER_STATE: "WORKER_STATE",
  UPDATE_USER_PROGRESS: "UPDATE_USER_PROGRESS",
  AUTO_CONFIGURE_RETRIEVER: "/auto-configure-retriever",
  ACTION_STEPS: "/upload-file",
  QUERY_HISTORY_SORTING_ORDER: "QUERY_HISTORY_SORTING_ORDER",
  CHANGE_USER_STATUS: "CHANGE_USER_STATUS",
  CRM_CODE: "CRM_CODE",
  INVITED_USER: "INVITED_USER",
  INVITE_TOKEN_ID: "INVITE_TOKEN_ID",
  FLOW_TOKEN: "FLOW_TOKEN",
};

export const tenantIdConstants = {
  ZBRAIN_DEMO_ACCOUNT_TENANTID: "6447a3f49922a96b9a3a49e8",
};

export const cardsData = [
  {
    image: "/images/public-app.svg",
    imageActive: "/images/public-app-active.svg",
    title: "Public App",
    type: "PUBLIC",
    description:
      "App link will be public and anyone with the link can use the application.",
  },
  {
    image: "/images/private-app.svg",
    imageActive: "/images/private-app-active.svg",
    title: "Private App",
    type: "PRIVATE",
    description:
      "App link will be private and only person with the link can use the application.",
  },
];

export const agentAccessType = [
  {
    image: "/images/public-app.svg",
    imageActive: "/images/public-app-active.svg",
    title: "Public Agent",
    type: "PUBLIC",
    description:
      "Anyone with the link will be able to access the agent dashboard and act as operator.",
  },
  {
    image: "/images/private-app.svg",
    imageActive: "/images/private-app-active.svg",
    title: "Private Agent",
    type: "PRIVATE",
    description:
      "Only invited operators will be able to access the agent dashboard.",
  },
];

export const orchestrationMethodData = [
  {
    image: "/images/KB-Default.svg",
    imageActive: "/images/KB-Selected.svg",
    title: "Knowledge Base",
    type: "BASIC",
    description: "Basic",
    mode: "BASIC",
  },
  {
    image: "/images/flow-inactive.svg",
    imageActive: "/images/flow-active.svg",
    title: "Flow",
    type: "ADVANCE",
    description: "Advanced",
    mode: "ADVANCE",
  },
];

export const manageAppsConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE",
    UDPATE_APP: "UDPATE_APP",
  },
};

export const manageWorkersConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE",
    UPDATE_STATE: "UPDATE_STATE",
    UDPATE_WORKERS: "UDPATE_WORKERS",
    UPDATE_PUBLIC_AGENT: "UPDATE_PUBLIC_AGENT"
  },
};

export const manageAgentsConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE",
    UDPATE_AGENT: "UDPATE_AGENT",
  },
};

export const manageWorkflowConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE",
    UDPATE_WORKFLOW: "UDPATE_WORKFLOW",
  },
};

export const manageModelsConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE",
    UDPATE_MODELS: "UDPATE_MODELS",
  },
};

export const errorMessagesConstants = {
  NAME_REQUIRED: "Username is required.",
  PASSWORD_REQUIRED: "Password is required.",
  PHONE_NUMBER_REQUIRED: "Phone Number is required.",
  PASSWORD_MUST_8_CHARACTERS: "Password must be at least 8 characters.",
  PASSWORD_ALPHA_NUMBER_ERROR:
    "Password must include letters, numbers and special characters.",
  INVALID_EMAIL: "Please enter a valid email.",
  INVALID_PASSWORD: "Invalid password.",
  NAME_IS_REQUIRED: "Please enter your name.",
  EMAIL_REQUIRED: "Please enter your email.",
  FEEDBACK_REQUIRED: "Please enter your feedback.",
  USERID_REQUIRED: "User ID is required.",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password is required.",
  PASSWORD_MUST_SAME: "New password and Confirm password must be same.",
  ERROR_OCCURED_RAZORPAY:
    "An error occurred while setting up razorpay, Please try again.",
  LIMIT_EXCEEDED:
    "You have exceeded your daily limit for stories. please consider upgrading your plan",
  LOW_CREDIT_BALANCE:
    "Your credit balance is too low. Please upgrade your account to proceed.",
  PLAN_EXPIRED: "Plan expired, Please upgrade the plan.",
  TENANT_INVITATION_ACCEPTED: "You have already given access to this account.",
  ADD_TEST_QUESTIONS: "Please add test questions.",
  PLATFORM_INVITE:
    "Please contact ZBrain support to access the platform. Reach us at hello@zbrain.com.",
  GENERIC_ERROR: "Something went wrong. Please try again.",
};

export const successMessages = {
  USER_INVITED: "User invited successfully.",
  USER_UPDATED: "User updated successfully.",
  COMPANY_SWITCHED: "Account switched successfully.",
  SETTINGS_UPDATED: "Settings updated successfully.",
  VERSION_DELETED: "Version deleted successfully.",
};

export const MAX_QUESTIONS = 9;
export const MIN_QUESTIONS = 3;
export const MAX_TEST_QUESTIONS = 3;
export const MIN_TEST_QUESTIONS = 1;
export const MAX_QUERY_PARAMS = 3;
export const MAX_HEADERS = 3;

export const termsOfuseData = [
  {
    title: "End User Terms of Use",
    content: `These End User Terms of Use govern the access and use of the services
      provided by ZBrain Inc. by the individual registering for an account.
      By registering an account or accessing and using the services, you
      agree to be bound by these End User Terms, including ZBrain's Privacy
      Policy and Acceptable Use Policy, as well as all applicable laws and
      regulations, and you are responsible for complying with any applicable
      local laws.`,
  },
  {
    title: "User's Account",
    content: `If you sign up for an account, you agree to use the Services in
      compliance with these End User Terms, ZBrain's terms and policies, and
      any applicable laws and regulations. When you submit digital files,
      data, or machine learning models to the ZBrain API, or otherwise
      provide User Materials to ZBrain to facilitate ZBrain's provision of
      the Services, you acknowledge and agree that the User Materials are
      owned by you. Any and all references to "your account" are references
      to your ZBrain account.`,
  },
  {
    title: "Relationship between ZBrain and User",
    content: `As between ZBrain and User, you agree that it is solely your
      responsibility to (a) obtain any rights, permissions, or consents that
      are necessary for the lawful use of User Materials and the operation
      of the Services, and (b) respond to and resolve any dispute with any
      third party relating to the Services, User Materials, or your failure
      to fulfill these obligations.`,
  },
  {
    title: "User Account and Registration",
    content: `To access most features of the Services, you must register for an account. When you register for an account, you may be required to provide us with some personal information (as described in our Privacy Policy, such as your name, email address, or other contact information), and you agree that the information you provide to us is accurate, and that you will keep it accurate and up-to-date at all times. When you register, you will be asked to provide a password, and you are solely responsible for maintaining the confidentiality of your account and password. You agree and acknowledge that you are solely responsible and liable for all activities that occur under your account. If you believe that your account is no longer secure, then you must immediately notify us at `,
  },
  {
    title: "Use of the Services ",
    content: `Subject to your complete and ongoing compliance with these End User Terms, ZBrain agrees you may use the Services during the Term, solely with supported browsers through the Internet for purposes authorized by ZBrain. Nothing in these End User Terms will be construed to grant you any right to transfer or assign rights to use the Services.`,
  },
  {
    title: "Reservation of Rights",
    content: `All rights not expressly granted to you are reserved by ZBrain and its licensors. You further acknowledge and agree that, as between you and ZBrain, ZBrain owns all right, title, and interest in and to the ZBrain Technology, including all Intellectual Property Rights therein. The visual interfaces, graphics, design, compilation, information, data, computer code (including source code or object code), products, software, services, and all other elements of the ZBrain Technology are protected by intellectual property and other laws.`,
  },
  {
    title: "Restrictions",
    content: `You may not permit the Services to be used by or for the benefit of unauthorized third parties, or for any purpose other than the purpose authorized by ZBrain. You may not (i) modify or make derivative works based upon the Services; (ii) reverse engineer the Services, or (iii) access or use the Services in order to (a) build a competitive product or service, or (b) build a product using similar features, functions, or graphics of`,
  },
  {
    title: "Termination",
    content: `You have the right to terminate your account at any time by following the instructions in your account settings or by contacting us at support@zbrain.ai. However, we reserve the right to terminate or suspend your access to the Services or your account with or without notice, for any reason or no reason, including if we believe that you have violated or acted inconsistently with these End User Terms or any applicable law or regulation. Upon termination, you must stop using the Services and delete any copies of the Services or related documentation in your possession.`,
  },
  {
    title: "Disclaimer of Warranties",
    content: `The Services are provided on an "as is" basis, and ZBrain disclaims all warranties, express or implied, including without limitation any implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. ZBrain does not guarantee that the Services will be uninterrupted, error-free, or completely secure. ZBrain will not be liable for any loss, damage, or injury arising out of or in connection with your use of the Services.`,
  },
  {
    title: "Limitation of Liability",
    content: ` In no event will ZBrain be liable for any indirect, incidental, consequential, special, or punitive damages arising out of or in connection with these End User Terms or the use of the Services, whether based on contract, tort, strict liability, or otherwise, even if ZBrain has been advised of the possibility of such damages. In no event will ZBrain’s total liability to you for all claims arising out of or in connection with these End User Terms or the use of the Services exceed the amounts paid by you to ZBrain for the Services during the six (6) months preceding the claim.`,
  },
  {
    title: "Indemnification",
    content: `You agree to indemnify, defend, and hold harmless ZBrain and its officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or in connection with your use of the Services or your violation of these End User Terms.`,
  },
  {
    title: "General    ",
    content: `These End User Terms, along with any applicable MSA or other agreement entered into by the Customer with ZBrain, constitute the entire agreement between you and ZBrain with respect to the Services and supersede all prior or contemporaneous communications and proposals (whether oral, written, or electronic) between you and ZBrain. If any provision of these End User Terms is found to be unenforceable or invalid, the remaining provisions will remain in full force and effect. ZBrain’s failure to enforce any right or provision of these End User Terms will not be deemed a waiver of such right or provision.`,
  },
];
export const PrivacyPolicyData = [
  {
    title: "Your Choices",
    content: `You may opt out of receiving marketing and promotional materials from us by following the instructions in the email or by contacting us at support@zbrain.ai. Please note that even if you opt out, we may still send you non-promotional messages, such as those related to your account or our ongoing business relationship.`,
  },
  {
    title: "Security    ",
    content: `We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.`,
  },
  {
    title: "Updates to this Policy",
    content: `We may update this Privacy Policy from time to time by posting a new version on our website. We encourage you to review this Privacy Policy periodically for any changes.`,
  },
  {
    title: "Contact Us",
    content: `If you have any questions or concerns about this Privacy Policy, please contact us at `,
  },
];
export const informationCollect = [
  "Personal Information: We may collect personal information that can be used to identify you, such as your name, email address, phone number, and payment information.",
  "Usage Information: We may collect information about how you use the Services, such as your IP address, browser type, and pages you view.",
  "Device Information: We may collect information about the device you use to access the Services, such as your device type, operating system, and mobile network information.",
  "Location Information: We may collect information about your location if you enable location services on your device.",
];
export const useOfInformation = [
  "Providing and improving the Services.",
  "Responding to your requests and inquiries.",
  "Communicating with you about the Services.",
  "Sending you marketing and promotional materials.",
  "Protecting our rights and property.",
  "Complying with applicable laws and regulations.",
];
export const shareOfInformation = [
  "Service Providers: We may share your information with third-party service providers that perform services on our behalf, such as payment processing, data analysis, and marketing..",
  " Business Transfers: We may share your information in connection with a merger, acquisition, or sale of all or a portion of our assets..",
  "Legal Requirements: We may disclose your information if required to doso by law or in the good faith belief that such action is necessary to comply with applicable laws or regulations, or to protect and defend  our rights or property.",
];

export const importTypes = {
  FILE: "FILE",
  MANUAL: "MANUAL",
  API: "API",
  WEBSITE: "WEBSITE",
  JIRA: "JIRA",
  SHEETS: "SHEETS",
  GMAIL: "GMAIL",
  DOCS: "DOCS",
  CALENDAR: "CALENDAR",
  NOTION: "NOTION",
  SLACK: "SLACK",
  SLIDE: "SLIDE",
  ELASTICSEARCH: "ELASTICSEARCH",
  WEBHOOK: "WEBHOOK",
  SERVICENOW: "SERVICENOW",
  POSTGRESQL: "POSTGRESQL",
  MONGODB: "MONGODB",
  CONFLUENCE: "CONFLUENCE",
  REDSHIFT: "REDSHIFT",
  SHAREPOINT: "SHAREPOINT",
  TEAMS: "TEAMS",
  ONEDRIVE: "ONEDRIVE",
  GOOGLEDRIVE: "GOOGLEDRIVE",
};

export const importConnectors = [
  {
    logo: "/images/web-url-icon.svg",
    name: "Web URL",
    type: importTypes.WEBSITE,
    upcoming: false,
  },
  {
    logo: "/images/api-logo-knowledgeBase.svg",
    name: "Webhook",
    type: importTypes.WEBHOOK,
    upcoming: false,
  },
  {
    logo: "/images/google-sheets-logo.jpg",
    name: "Google Sheets",
    type: importTypes.SHEETS,
    upcoming: false,
  },
  {
    logo: "/images/google-slides-logo.png",
    name: "Google Slides",
    type: importTypes.SLIDE,
    upcoming: false,
  },
  {
    logo: "/images/google-docs-logo.png",
    name: "Google Docs",
    type: importTypes.DOCS,
    upcoming: false,
  },
  {
    logo: "/images/elastic-search-logo.svg",
    name: "ElasticSearch",
    type: importTypes.ELASTICSEARCH,
    upcoming: false,
  },
  {
    logo: "/images/notion-logo.png",
    name: "Notion",
    type: importTypes.NOTION,
    upcoming: false,
  },
  {
    logo: "/images/mongo-logo.png",
    name: "MongoDB",
    type: importTypes.MONGODB,
    upcoming: false,
  },
  {
    logo: "/images/servicenow-logo.png",
    name: "ServiceNow",
    type: importTypes.SERVICENOW,
    upcoming: false,
  },
  {
    logo: "/images/confluence.png",
    name: "Confluence",
    type: importTypes.CONFLUENCE,
    upcoming: false,
  },
  {
    logo: "/images/jira-logo.svg",
    name: "JIRA",
    type: importTypes.JIRA,
    upcoming: false,
  },
  {
    logo: "/images/postgresql-logo.png",
    name: "PostgreSQL",
    type: importTypes.POSTGRESQL,
    upcoming: false,
  },
  {
    logo: "/images/aws-redShift.png",
    name: "AWS RedShift",
    type: importTypes.REDSHIFT,
    upcoming: false,
  },

  {
    logo: "/images/sharePoint.png",
    name: "SharePoint",
    type: importTypes.SHAREPOINT,
    upcoming: false,
  },
  {
    logo: "/images/teams.webp",
    name: "Microsoft Teams",
    type: importTypes.TEAMS,
    upcoming: false,
  },
  {
    logo: "/images/oneDrive.svg",
    name: "One Drive",
    type: importTypes.ONEDRIVE,
    upcoming: false,
  },
  {
    logo: "/images/google-drive-logo.svg",
    name: "Google Drive",
    type: importTypes.GOOGLEDRIVE,
    upcoming: false,
  },
  {
    logo: "/images/gmail-logo.svg",
    name: "Gmail",
    type: importTypes.GMAIL,
    upcoming: true,
  },
  {
    logo: "/images/google-calendar-logo.png",
    name: "Google Calendar",
    type: importTypes.CALENDAR,
    upcoming: true,
  },

  {
    logo: "/images/slack-logo.png",
    name: "Slack",
    type: importTypes.SLACK,
    upcoming: true,
  },
  {
    logo: "/images/aws-s3-logo.png",
    name: "AWS S3",
    upcoming: true,
  },
  {
    logo: "/images/ms-excel-logo.svg",
    name: "Excel",
    upcoming: true,
  },

  {
    logo: "/images/github-logo.png",
    name: "Github",
    upcoming: true,
  },
  {
    logo: "/images/database-logo.jpg",
    name: "Database",
    upcoming: true,
  },
  {
    logo: "/images/reddit-logo.png",
    name: "Reddit",
    upcoming: true,
  },
  {
    logo: "/images/zendesk-logo.png",
    name: "Zendesk",
    upcoming: true,
  },
  {
    logo: "/images/youtube-logo.png",
    name: "Youtube",
    upcoming: true,
  },
];

export const themes = [
  {
    theme: "/images/lightmode1.svg",
    name: "Light mode 1",
    styles: {
      sideBarColor: "#202020",
      sideBarText: "#FFFFFF",
      backGroundColor: "#FFFFFF",
      textColor: "#454545",
      sampleQueryColor: "#454545",
      botReplyBg: "#F7F7F8",
    },
  },
  {
    theme: "/images/lightmode2.svg",
    name: "Light mode 2",
    styles: {
      sideBarColor: "#FFFFFF",
      sideBarText: "#474747",
      backGroundColor: "#F7F7F7",
      textColor: "#474747",
      sampleQueryColor: "#F1F1F1",
      botReplyBg: "#FFFFFF",
    },
  },
  {
    theme: "/images/darkmode.svg",
    name: "Dark mode",
    styles: {
      sideBarColor: "#393939",
      sideBarText: "#FFFFFF",
      backGroundColor: "#2C2B2B",
      textColor: "#FFFFFF",
      sampleQueryColor: "#474747",
      botReplyBg: "#343434",
    },
  },
  {
    theme: "/images/bluetheme.svg",
    name: "Blue",
    styles: {
      sideBarColor: "#2684FF",
      sideBarText: "#FFFFFF",
      backGroundColor: "#FFFFFF",
      textColor: "#131426",
      sampleQueryColor: "#59A1FF",
      botReplyBg: "#F7F7F8",
    },
  },
  {
    theme: "/images/greentheme.svg",
    name: "Green",
    styles: {
      sideBarColor: "#5DC5AC",
      sideBarText: "#FFFFFF",
      backGroundColor: "#FFFFFF",
      textColor: "#131426",
      sampleQueryColor: "#7EDFC8",
      botReplyBg: "#F7F7F8",
    },
  },
  {
    theme: "/images/purpletheme.svg",
    name: "Purple",
    styles: {
      sideBarColor: "#310197",
      sideBarText: "#FFFFFF",
      backGroundColor: "#FFFFFF",
      textColor: "#131426",
      sampleQueryColor: "#591DDA",
      botReplyBg: "#F7F7F8",
    },
  },
  {
    theme: "/images/dark-blue-theme.svg",
    name: "Dark Blue",
    styles: {
      sideBarColor: "#001E34",
      sideBarText: "#FFFFFF",
      backGroundColor: "#FFFFFF",
      textColor: "#131426",
      sampleQueryColor: "#021726",
      botReplyBg: "#f6f6f6",
    },
  },
];

export const recordElements = {
  activity: "ACTIVITY",
  input: "INPUT",
  output: "OUTPUT",
};

export const publicApps = [
  {
    name: "ChatGPT for BMW X4 Spec",
    url: "https://app.zbrain.ai/chat/647598723c69420024fcf688?tenantId=6447a3f49922a96b9a3a49e8",
  },
  {
    name: "ChatGPT for Arthritis",
    url: "https://app.zbrain.ai/chat/6475a308289e280025094cf5?tenantId=6447a3f49922a96b9a3a49e8",
  },
  {
    name: "ChatGPT for McDonald’s Handbook",
    url: "https://app.zbrain.ai/chat/6475eff2289e2800250955b2?tenantId=6447a3f49922a96b9a3a49e8",
  },
  {
    name: "ChatGPT for Marriot Quarterly Report",
    url: "https://app.zbrain.ai/chat/6475f198289e2800250955eb?tenantId=6447a3f49922a96b9a3a49e8",
  },
  {
    name: "ChatGPT for GE Annual Report",
    url: "https://app.zbrain.ai/chat/6475f2d0289e28002509561c?tenantId=6447a3f49922a96b9a3a49e8",
  },
  {
    name: "ChatGPT for Stanford Facts",
    url: "https://app.zbrain.ai/chat/6475f3b1289e280025095653?tenantId=6447a3f49922a96b9a3a49e8",
  },
  {
    name: "ChatGPT for Apple Annual Report",
    url: "https://app.zbrain.ai/chat/6475f4cb289e280025095698?tenantId=6447a3f49922a96b9a3a49e8",
  },
  {
    name: "ChatGPT for Citi Group Investors",
    url: "https://app.zbrain.ai/chat/6475f5ec289e2800250956c9?tenantId=6447a3f49922a96b9a3a49e8",
  },
];
export const likeDislikeStatus = {
  OPEN: "Open",
  REQUIRE_ATTENTION: "Require Attention",
  CLOSED: "Closed",
};

export const logData = {
  status: "SUCCESS",
  elapsedTime: "3.215s",
  totalTokens: 1001,
  input: {
    "Sys-Query": "Can You Explain Twinpower Turbo Engine",
    "Sys-Files": "01",
    "Sys-Conversation_id": "fcf38f00-dcda-4bfe-872b-0d1101852bd8",
    "Sys-User_id": "01840507-3544-4a88-9ea5-7ce8364c1758",
  },
  output: {
    "Sys-Query": "Greetings! The TwinPower Turbo Engine Is",
    "Sys-Files": "01",
    "Sys-Conversation_id": "fcf38f00-dcda-4bfe-872b-0d1101852bd8",
    "Sys-User_id": "01840507-3544-4a88-9ea5-7ce8364c1758",
  },
  metadata: {
    instructions:
      "You are a friendly, polite customer service agent for ZBrain. You need to answer Question in the same language.",
    model: "GPT 3.5/4",
    temp: 0.7,
    inputToken: 4096,
  },
};

export const contextData = [
  {
    id: "001",
    matchingScore: 98,
    title: "BMW 3 SERIES",
    description:
      "Near-perfect weight balance. Smooth engines. Refined road manners. For over 40 years, the BMW 3 Series has shown everyone what a true...",
    source: "BMW 3 Series 2024",
  },
  {
    id: "001",
    matchingScore: 98,
    title: "BMW 3 SERIES",
    description:
      "Near-perfect weight balance. Smooth engines. Refined road manners. For over 40 years, the BMW 3 Series has shown everyone what a true...",
    source: "BMW 3 Series 2024",
  },
  {
    id: "001",
    matchingScore: 98,
    title: "BMW 3 SERIES",
    description:
      "Near-perfect weight balance. Smooth engines. Refined road manners. For over 40 years, the BMW 3 Series has shown everyone what a true...",
    source: "BMW 3 Series 2024",
  },
];

export const requestTypeOptions = [
  { value: "GET", label: "GET" },
  { value: "POST", label: "POST" },
  { value: "PUT", label: "PUT" },
  { value: "PATCH", label: "PATCH" },
];
export const sendAsJsonOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const notionSteps = [
  {
    title: "Integration Token:-",
    points: [
      {
        text: "To initiate the process, start by accessing the Settings & members option in your left sidebar and then proceed to the My Connections tab.",
        highlighted: ["Settings & members", "My Connections"],
      },
      {
        text: "Please note that only Workspace owners will be able to access the Integrations tab and create integrations. If you are not an admin, kindly get in touch with your Workspace admin or consider utilizing a different Workspace.",
        highlighted: ["Integrations"],
      },
      {
        text: "Click on the Develop or manage integrations link at the bottom.",
        highlighted: ["Develop or manage integrations"],
      },
      {
        text: "Upon clicking, your default browser will open, displaying the interface for your integration. Now, select the + New integration button to initiate the integration creation process.",
        highlighted: ["+ New integration"],
      },
      {
        text: "Provide a suitable Name for your integration. Optionally, you can also upload an image that will serve as the integration's Logo.",
        highlighted: ["Name", "Logo"],
      },
      {
        text: "Within the dropdown menu, specify the relevant Associated workspace for which you intend to use this integration.",
        highlighted: ["Associated workspace"],
      },
      {
        text: 'After completing these steps, click the "Submit" button. This action will confirm the successful addition of your internal integration.',
      },
      {
        text: "Finally, make use of the generated token as your integration token in ZBrain.",
      },
    ],
  },
  {
    title: "Page Ids:-",
    points: [
      {
        text: "Locate and click on the ••• icon positioned at the upper-right corner of your page. This will provide access to the necessary settings for connecting to your internal integration token.",
      },
      {
        text: 'Next, navigate to the "Connections" section to connect the recently created integration token with the page.',
      },
      {
        text: 'With this connection established, you can now utilize the obtained "pageId" within ZBrain for seamless integration. The following is the "pageId" for your reference: For example, in a URL like https://www.notion.so/test-12345678902wqdd, the segment 12345678902wqdd is the pageId.',
        link: ["https://www.notion.so/test-12345678902wqdd"],
      },
    ],
  },
];

export const webhookSteps = [
  {
    points: [
      {
        title: "URL -",
        text: "To retrieve data from the API, it is essential to provide the Base URL with the API endpoint. For example, https://www.example.com/test.",
        link: ["https://www.example.com/test"],
      },
      {
        title: ["Request Type Selection -"],
        text: "Choose the appropriate request type for your API from the dropdown menu. Options include GET, POST, PUT, and PATCH.",
      },
      {
        title: "Request Body -",
        text: "If body parameters are required in the API request, you can send the data as a JSON object. Alternatively, in cases where body parameters are not required, you can simply leave it as empty.",
      },
      {
        title: "Query String Params -",
        text: "If query parameters are required in the API request, you have the option to send key-value pairs; else, if query parameters are unnecessary, leaving them empty is a valid choice.",
      },
      {
        title: "Send As JSON -",
        text: 'From the dropdown menu, you can select yes or no on the basis of your API response type. If the response type of API is JSON, select “Yes,” and if the API response type is text, then select “No.” Note that the default selection is "Yes."',
      },
      {
        title: "Basic Auth -",
        text: "If the API requires a basic type of authentication, you need to provide the username and password combination separated by a pipe (|) symbol. For example - username|password. However, if basic authentication is not required in the API request, you can leave this field empty.",
      },
      {
        title: "Headers -",
        text: "If you want to add header parameters in the API request, you have the option to send key-value pairs. Alternatively, if header parameters are unnecessary, you can leave this section empty.",
      },
      {
        title: "JQ Schema -",
        text: "In case the response type is JSON and you want to create knowledge base imports by selecting specific keys from the API response, you can supply a JQ schema. This ensures that only the keys mentioned in the JQ schema are utilized for creating the knowledge base. If your objective is to generate knowledge base imports encompassing all API response keys, you can leave this field empty.",
      },
    ],
  },
];

export const elasticSearchSteps = [
  {
    title: "Elastic Search URL:",
    points: [
      {
        text: "Access Elastic Search by logging in and then navigating to the Integrations section in the side menu bar.",
      },
      {
        text: "Locate 'API' within the integration options list, and click on it.",
      },
      {
        text: "Next, you'll be asked to create an Elastic Search index. Proceed by naming the index and leaving the Language Analyzer as default.",
      },
      {
        text: "Once created, open the index and retrieve the base URL from the provided curl command used to add data via API.",
      },
    ],
  },
  {
    title: "Elastic Search Index Name:",
    points: [
      {
        text: "In the index name field, you can enter the name of an existing index from which you intend to extract the data.",
      },
    ],
  },
  {
    title: "Elastic Search Query:",
    points: [
      {
        text: "You can input a JSON query to retrieve filtered data from the ElasticSearch query. Alternatively, if you wish to extract all data without filters, you can leave the field as empty curly braces - {}.",
      },
    ],
  },
  {
    title: "Common Key:",
    points: [
      {
        text: "Within the index, it's essential for all objects to share at least one common key name, which needs to be specified in this field.",
      },
    ],
  },
  {
    title: "Elastic Search API Key:",
    points: [
      {
        text: "On the page where the Elastic Search URL was obtained, you'll find a button to manage API Keys. Utilize this button to generate an API Key, which is a mandatory requirement.",
      },
    ],
  },
];
export const serviceNowSteps = [
  {
    title: "Table Name:",
    points: [
      {
        text: " In ServiceNow, tables are used to store data in a structured manner. Each table represents a specific type of record or data entity. ",
      },
    ],
  },
  {
    title: "Instance Name:",
    points: [
      {
        text: " An instance name refers to the unique identifier for a specific instance of the ServiceNow platform. Each ServiceNow instance has a distinct instance name. The format of the URL to access a ServiceNow instance typically looks like this: https://{instance-name}.service-now.com",

        link: ["https://{instance-name}.service-now.com"],
      },
    ],
  },
  {
    title: "Username:",
    points: [
      {
        text: " A username typically refers to the user's login name or user ID, which is used to access the ServiceNow platform.",
      },
    ],
  },
  {
    title: "Password:",

    points: [
      {
        text: " Password are used as a security measure to authenticate and grant access to the platform. Users are required to provide valid password to log in to the ServiceNow instance.",
      },
    ],
  },
  {
    title: "Skip:",
    points: [
      {
        text: " Skip method is used to skip a specified number of result set  in a table and retrieve the  result set that come after the skipped ones.        ",
      },
    ],
  },
  {
    title: "Limit:",
    points: [
      {
        text: " Limit method is used to specify the maximum number of entries that should be returned in the result. It allows you to control the size of the result set, which can be especially useful when dealing with large table.        ",
      },
    ],
  },
  {
    title: "Filter:",
    points: [
      {
        text: "Applying a filter implies a change in the display of the contents. Consequently, it will expect to see a change or expect to view only the information and elements that interest        ",
      },
    ],
  },
];

export const googleSteps = [
  {
    points: [
      {
        text: "To establish a knowledge base using these connectors, providing their public URLs is essential.",
      },
      {
        text: 'To get the public URL, click on the share button and in the "general access" dropdown, opt for "Anyone with the link." Afterward, copy the generated Public URL.',
      },
    ],
  },
];

export const webUrlSteps = [
  {
    points: [
      {
        title: "Website URL -",
        text: "In this field, input any desired website URL from which data extraction is required.",
      },
      {
        title: "Number of pages to index -",
        text: "Specify the crawling limit in this field to crawl additional website pages for data extraction. The maximum limit for crawling is 50 pages.",
      },
    ],
  },
];

export const redShiftSteps = [
  {
    title: "Host:",
    points: [
      {
        text: "The hostname of the Amazon Redshift cluster.",
      },
      {
        text: "Example: 'example-cluster-name.region.redshift.amazonaws.com'",
      },
    ],
  },
  {
    title: "DB Name:",
    points: [
      {
        text: "The name of the database you want to connect to.",
      },
      {
        text: "Example: 'dev'",
      },
    ],
  },
  {
    title: "Port:",
    points: [
      {
        text: "The port number used by the Amazon Redshift cluster for connections. The default port for Amazon Redshift is 5439.",
      },
      {
        text: "Example: '5439'",
      },
    ],
  },
  {
    title: "Username:",

    points: [
      {
        text: "The username used to authenticate and connect to the specified database.",
      },
    ],
  },
  {
    title: "Password:",
    points: [
      {
        text: "The password associated with the provided username for authentication.",
      },
    ],
  },
  {
    title: "Queries:",
    points: [
      {
        text: "An list of SQL queries that you want to execute after establishing the connection. These queries can be used for tasks like data retrieval.",
      },
      {
        text: "Example:",
      },
      {
        text: "SET search_path to tickit', 'SELECT * FROM listing LIMIT 50;",
      },
    ],
  },
];

export const sharePointSteps = [
  {
    title:
      "Directory (tenant) ID, Application (client) ID and Secret Value (Client Credentials):",
    points: [
      {
        text: "Navigate to Azure Portal.",
      },
      {
        text: "Click on Azure Active Directory -> App registrations -> New registration.",
      },
      {
        text: "Enter a name for the app and select the supported account types.",
      },
      {
        text: "Click on Register. Note down the Application (client) ID and the Directory (tenant) ID values provided on the application overview page.",
      },
      {
        text: "In the application's overview page, go to Certificates & secrets.",
      },
      {
        text: "Click on New client secret.",
      },
      {
        text: "Enter a description and an expiration date for the secret.",
      },
      {
        text: "Click Add and note down the value of the Client Secret immediately.",
      },
      {
        text: "In the application's overview page, navigate to API permissions -> Add a permission -> Microsoft APIs -> Choose Microsoft Graph -> Select Application permissions -> Add Files.Read.All and Sites.Read.All permissions.",
      },
      {
        text: "In the API permissions tab, click on Grant admin consent for [Your Directory]. Confirm the action to grant the necessary permissions. Admin consent is required to use application permissions without user consent.",
      },
    ],
  },
  {
    title: "Important Note:",
    points: [
      {
        text: "An administrator of the Azure AD tenant must grant consent for these permissions. Until consent is granted, these permissions won't be effective. Make sure to coordinate with the Azure AD administrator to ensure that the necessary permissions are granted.",
      },
    ],
  },
  {
    title: "SharePoint URL:",
    points: [
      {
        text: "Navigate to the documents folder in your SharePoint site and copy the url, and then paste it.",
      },
    ],
  },
  {
    title: "Hostname, Site Name and Folder Path:",

    points: [
      {
        text: "Extract the hostname, site name and folder path from the SharePoint URL. For example, if the URL is:",
      },
      {
        text: "https://example.sharepoint.com/sites/Marketing/demo/docs/Forms/AllItems.aspx?e=2%GMd&at=9",
        link: [
          "https://example.sharepoint.com/sites/Marketing/demo/docs/Forms/AllItems.aspx?e=2%GMd&at=9",
        ],
      },
      {
        text: "The part of the URL before the first /sites/. In example URL, the hostname is [example.sharepoint.com].",
      },
      {
        text: "The part of the URL after the first /sites/ and before the next /. In example URL, the site name is Marketing.",
      },
      {
        text: "The part of the URL after the site name and before the query parameters (? or #). Ignore unnecessary path components (Forms/AllItems.aspx). In example URL, the folder path is /demo/docs.",
      },
      {
        text: "Note: Sometimes, the folder path might be present in the query parameters. Extract the folder path from the `id` value inside the query parameters after '/sites/<site name>'. Ignore unnecessary path components like '/Shared%20Documents' and 'Forms/AllItems.aspx'",
      },
    ],
  },
  {
    title: "File Types:",
    points: [
      {
        text: "Provide a list of file extensions that need to be read, for example: pdf, csv, docx, etc.",
      },
    ],
  },
  {
    title: "Number of Files:",
    points: [
      {
        text: "Specify the maximum number of files that need to be read.",
      },
    ],
  },
  {
    title: "Date Range:",
    points: [
      {
        text: "The system will only pick the files that fall within the specified date range.",
      },
    ],
  },
];
export const teamSteps = [
  {
    title:
      "Directory (tenant) ID, Application (client) ID and Secret Value (Client Credentials):",
    points: [
      {
        text: "Navigate to Azure Portal.",
      },
      {
        text: "Click on Azure Active Directory -> App registrations -> New registration.",
      },
      {
        text: "Enter a name for the app and select the supported account types.",
      },
      {
        text: "Click on Register. Note down the Application (client) ID and the Directory (tenant) ID values provided on the application overview page.",
      },
      {
        text: "In the application's overview page, go to Certificates & secrets.",
      },
      {
        text: "Click on New client secret.",
      },
      {
        text: "Enter a description and an expiration date for the secret.",
      },
      {
        text: "Click Add and note down the value of the Client Secret immediately.",
      },
      {
        text: "In the application's overview page, navigate to API permissions -> Add a permission -> Microsoft APIs -> Choose Microsoft Graph -> Select Application permissions -> Add Files.Read.All and Sites.Read.All permissions.",
      },
      {
        text: "In the API permissions tab, click on Grant admin consent for [Your Directory]. Confirm the action to grant the necessary permissions. Admin consent is required to use application permissions without user consent.",
      },
    ],
  },
  {
    title: "Important Note:",
    points: [
      {
        text: "An administrator of the Azure AD tenant must grant consent for these permissions. Until consent is granted, these permissions won't be effective. Make sure to coordinate with the Azure AD administrator to ensure that the necessary permissions are granted.",
      },
    ],
  },
  {
    title: "Team URL:",
    points: [
      {
        text: "In Microsoft Teams, Select the specific team for which you want to get the link, click on the three-dot menu icon, select 'Get link to team' and note down the team link displayed",
      },
    ],
  },
  {
    title: "Team ID:",

    points: [
      {
        text: "Extract the Team ID from the team URL. For example, if the URL is:",
      },
      {
        anchor: [
          "https://teams.microsoft.com/l/team/19:y_lncpknnm_5luofvbupb7g6eunkw5hbq3whexdts21v@thread.tacv2/conversations?groupId=8bfe0672-633b-11ee-8c99-0242ac120002&tenantId=7c58f2b8-633b-11ee-8c99-0242ac120002",
        ],
      },
      {
        text: "Find the section after the question mark (?) in the URL, which contains query parameters.",
      },
      {
        text: "Look for the parameter named 'groupId' within the query parameters. In the provided URL, the parameter 'groupId' is followed by an equals sign (=).",
      },
      {
        text: 'Retrieve the characters after the equals sign (=) following "groupId". In the example URL (groupId=8bfe0672-633b-11ee-8c99-0242ac120002), the Group ID is 8bfe0672-633b-11ee-8c99-0242ac120002. Note down the group ID',
      },
    ],
  },
  {
    title: "Query:",
    points: [
      {
        text: "Query to retrieve filtered messages from the team. You can use the $top ",
      },
      {
        anchor:
          "https://learn.microsoft.com/en-us/graph/query-parameters?tabs=http#top-parameter",
      },
      {
        text: "query parameter to control the number of items. Additionally, $filter ",
      },
      {
        anchor:
          "https://learn.microsoft.com/en-us/graph/query-parameters?tabs=http#filter-parameter",
      },
      {
        text: "is supported with dateTime range query on lastModifiedDateTime.",
      },

      {
        text: "Example: {}",
      },
    ],
  },
];

export const oneDriveSteps = [
  {
    title:
      "Directory (tenant) ID, Application (client) ID and Secret Value (Client Credentials):",
    points: [
      {
        text: "Navigate to Azure Portal.",
      },
      {
        anchor: ["https://portal.azure.com/"],
      },
      {
        text: "Click on Azure Active Directory -> App registrations -> New registration.",
      },
      {
        text: "Enter a name for the app and select the supported account types.",
      },
      {
        text: "Click on Register. Note down the Application (client) ID and the Directory (tenant) ID values provided on the application overview page.",
      },
      {
        text: "In the application's overview page, go to Certificates & secrets.",
      },
      {
        text: "Click on New client secret.",
      },
      {
        text: "Enter a description and an expiration date for the secret.",
      },
      {
        text: "Click Add and note down the value of the Client Secret immediately.",
      },
      {
        text: "In the application's overview page, navigate to API permissions -> Add a permission -> Microsoft APIs -> Choose Microsoft Graph -> Select Application permissions -> Add Files.Read.All and Sites.Read.All permissions.",
      },
      {
        text: "In the API permissions tab, click on Grant admin consent for [Your Directory]. Confirm the action to grant the necessary permissions. Admin consent is required to use application permissions without user consent.",
      },
    ],
  },
  {
    title: "Important Note:",
    points: [
      {
        text: "An administrator of the Azure AD tenant must grant consent for these permissions. Until consent is granted, these permissions won't be effective. Make sure to coordinate with the Azure AD administrator to ensure that the necessary permissions are granted.",
      },
    ],
  },
  {
    title: "OneDrive URL (Recommended):",
    points: [
      {
        text: "Navigate to the documents folder in your OneDrive site and copy the url, and then paste it.",
      },
    ],
  },
  {
    title: "Hostname, Site Name and Folder Path:",

    points: [
      {
        text: "Extract the hostname, site name and folder path from the OneDrive URL. For example, if the URL is:",
      },
      {
        anchor: [
          "https://example-my.sharepoint.com/personal/name_example_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%name%example%5Fcom%2FDocuments%2FDocs&view=0",
        ],
      },
      {
        text: "The part of the URL before the first /personal/. In example URL, the hostname is [example-my.sharepoint.com].",
      },
      {
        text: "The part of the URL after the first /personal/ and before the next /. In example URL, the site name is name_example_com.",
      },
      {
        text: "The part of the URL in the query parameters in `id` value after the /personal/<site name>. Ignore unnecessary path components (Documents). In example URL, the folder path is Docs.",
      },
    ],
  },
  {
    title: "File Types:",
    points: [
      {
        text: "Provide a list of file extensions that need to be read, for example: pdf, csv, docx, etc.",
      },
    ],
  },
  {
    title: "Number of Files:",
    points: [
      {
        text: "Specify the maximum number of files that need to be read.",
      },
    ],
  },
];
export const googledriveSteps = [
  {
    title: "GoogleDrive URL (Recommended):",
    points: [
      {
        text: "Navigate to the documents folder in your GoogleDrive site and copy the url, and then paste it.",
      },
    ],
  },

  {
    title: "File Types:",
    points: [
      {
        text: "Provide a list of file extensions that need to be read, for example: pdf, csv, docx, etc.",
      },
    ],
  },
  {
    title: "Limit:",
    points: [
      {
        text: "Limit method is used to specify the maximum number of documents that should be returned in the result set of a query. It allows you to control the size of the result set, which can be especially useful when dealing with large collections or when you only need a subset of documents from a query.",
      },
    ],
  },
  {
    title: "Skip:",
    points: [
      {
        text: "Skip method is used to skip a specified number of documents in a collection and retrieve the documents that come after the skipped ones. It is often used in combination with the find method to implement pagination or to skip a certain number of documents that do not need to be included in the result set.",
      },
    ],
  },
];

export const modelTypes = [
  {
    value: "text-davinci-003",
    label: "text-davinci-003",
  },
  {
    value: "gpt-3.5-turbo",
    label: "gpt-3.5-turbo",
  },
  {
    value: "gpt-3.5-turbo-16k",
    label: "gpt-3.5-turbo-16k",
  },
  {
    value: "gpt-3.5-turbo-16k-0613",
    label: "gpt-3.5-turbo-16k-0613",
  },
  {
    value: "vicuna",
    label: "vicuna",
  },
];

export const paidModelTypes = [
  {
    value: "text-davinci-003",
    label: "text-davinci-003",
  },
  {
    value: "gpt-3.5-turbo",
    label: "gpt-3.5-turbo",
  },
  {
    value: "gpt-3.5-turbo-16k",
    label: "gpt-3.5-turbo-16k",
  },
  {
    value: "gpt-3.5-turbo-16k-0613",
    label: "gpt-3.5-turbo-16k-0613",
  },
  {
    value: "gpt-4",
    label: "gpt-4",
  },
  {
    value: "gpt-4-0613",
    label: "gpt-4-0613",
  },
  {
    value: "gpt-4-1106-preview",
    label: "gpt-4-1106-preview",
  },
  {
    value: "gpt-4-0125-preview",
    label: "gpt-4-0125-preview",
  },
  { value: "gpt-4-turbo-preview", label: "gpt-4-turbo-preview" },
  {
    value: "gpt-4-turbo-preview",
    label: "gpt-4-turbo-preview",
  },
  {
    value: "vicuna",
    label: "vicuna",
  },
];

export const retriever = [
  {
    value: "zbrain.cosine",
    label: "Zbrain Cosine",
  },
  {
    value: "langchain.hypothetical.multivector",
    label: "Langchain Hypthetical Multivector",
  },
  {
    value: "langchain.summary.multivector",
    label: "Langchain Summary Multivector",
  },
  {
    value: "langchain.smaller.multivector",
    label: "Langchain Smaller Multivector",
  },
  {
    value: "langchain.marginal.vectorbacked",
    label: "Langchain Marginal Vector Backed",
  },
  {
    value: "langchain.score.vectorbacked",
    label: "Langchain Score Vector Backed",
  },
  {
    value: "langchain.topk.vectorbacked",
    label: "Langchain TopK Vector Backed",
  },
  {
    value: "langchain.llmchainextractor.contextualcompression",
    label: "Langchain Extractor Contextual Retriever",
  },
  {
    value: "langchain.llmchainfilter.contextualcompression",
    label: "Langchain Filter Contextual Retriever",
  },
  {
    value: "langchain.embeddingsfilter.contextualcompression",
    label: "Langchain Embedding Filter Contextual Retriever",
  },
  {
    value:
      "langchain.stringingcompressor_with_documenttransformers.contextualcompression",
    label: "Langchain  Compressor Contextual Retriever",
  },
];

export const toastType = {
  SUCCESS: "success",
  ERROR: "error",
};

export const toastToggleKbMsg = "Please switch the knowledge base from all associated apps before disabling."
export const mongoDB = [
  {
    title: "MongoDB Connection String:",
    points: [
      {
        text: "To connect to your MongoDB database, please provide the MongoDB connection string. The connection string contains all the necessary information to establish a connection to your MongoDB server.",
      },
      {
        text: "Example Connection String Format:",
      },
      {
        text: "mongodb+srv://username:password@cluster.mongodb.net/database?retryWrites=true&w=majority",
      },
    ],
  },
  {
    title: "Collection Name:",
    points: [{ text: "MongoDB Collection Name" }],
  },
  {
    title: "Query:",
    points: [
      {
        text: "To retrieve specific data from the MongoDB database, you can enter queries in a structured format. MongoDB queries consist of JSON-like documents that specify the conditions for data retrieval.",
      },
      { text: "Example:" },
      { text: `{"field_name": "desired_value"}` },
    ],
  },
  {
    title: "Limit:",
    points: [
      {
        text: "Limit method is used to specify the maximum number of documents that should be returned in the result set of a query. It allows you to control the size of the result set, which can be especially useful when dealing with large collections or when you only need a subset of documents from a query.",
      },
    ],
  },
  {
    title: "Skip:",
    points: [
      {
        text: "Skip method is used to skip a specified number of documents in a collection and retrieve the documents that come after the skipped ones. It is often used in combination with the find method to implement pagination or to skip a certain number of documents that do not need to be included in the result set.",
      },
    ],
  },
];

export const PostgreSQL = [
  {
    title: "DB Name:",
    points: [
      {
        text: "Data Base name for Postgres",
      },
    ],
  },
  {
    title: "Port:",
    points: [
      {
        text: "When you connect to a Postgres database from a client application, you typically specify the host and port as part of the connection string. If you don't explicitly specify a port number, most PostgreSQL client libraries and tools will assume the default port number 5432.",
      },
    ],
  },
  {
    title: "Username:",
    points: [
      {
        text: "Username is used to define user accounts that can access and interact with the database server. Each user has their own set of privileges and permissions within the database system, a default superuser named `postgres` is typically created. ",
      },
    ],
  },
  {
    title: "Password:",
    points: [
      {
        text: "Password to access and interact with database server.",
      },
    ],
  },
  {
    title: "Host:",
    points: [
      {
        text: "Host for a Postgres database connection refers to the location or address of the PostgreSQL server where your database is hosted. The host can be specified in different ways, depending on your setup and where the PostgreSQL server is located.",
      },
    ],
  },
  {
    title: "Query:",
    points: [
      {
        text: "To run queries in Postgres, you typically use SQL. Here are some common type of SQL queries you can run in PostgreSQL:",
      },
      {
        text: "Example:",
      },
      {
        text: "SELECT column1, column2 FROM table_name WHERE condition;",
      },
    ],
  },
];

export const confluence = [
  {
    title: "Email:",
    points: [
      {
        text: "User email addresss",
      },
    ],
  },
  {
    title: "Server URL:",
    points: [
      {
        text: "The server URL is the URL that you or your organization is using.",
      },
      {
        text: "Example:- example.atlassian.net",
      },
    ],
  },
  {
    title: "Space key:",
    points: [
      {
        text: "It is unique identifier assigned to each space within the confluence. A space is a container for pages, like a project or a department",
      },
      {
        text: "To find the space key in Confluence from the URLs:",
      },
      {
        text: "Example:- https://your-confluence-instance/wiki/spaces/{SPACEKEY}/pages/{PAGEID}/Page-Title",
      },
    ],
  },
  {
    title: "Page Id:",
    points: [
      {
        text: "In Confluence, each page is assigned a unique Page ID. This Page ID is a numerical identifier that allows Confluence to distinguish one page from another, even if they have the same or similar names.",
      },
      {
        text: "Example: https://example.atlassian.net/wiki/spaces/pages/{pageId}",
      },
    ],
  },
  {
    title: "API Token:",
    points: [
      {
        text: "API tokens are used in place of traditional passwords to provide secure access to Confluence. Here's how you can generate and use an API token in Confluence:",
      },
      {
        text: "Here's how you can generate and use an API token in Confluence:",
      },
    ],
  },
  {
    title: "Generating an API Token:",
    points: [
      {
        text: "Log in to Confluence: Log in to your Confluence account using your regular username and password.",
      },
      {
        text: "Click on your profile picture or username in the top right corner.",
      },
      {
        text: "Navigate to Your Profile Settings:",
      },
      {
        text: "Select Profile.",
      },
      {
        text: "Click on Manage Your Account.",
      },
      {
        text: "Then click on Security in header.",
      },
      {
        text: "Then Create and Manage API Tokens or similar button.",
      },
      {
        text: "Generate Token.",
      },
      {
        text: "Once generated, copy the API token to a secure location. This token will not be shown again, so make sure to keep it safe.",
      },
    ],
  },
];
export const jira = [
  {
    title: "Email:",
    points: [
      {
        text: "User email addresss",
      },
    ],
  },
  {
    title: "Server URL:",
    points: [
      {
        text: "The server URL is the URL that you or your organization is using.",
      },
      {
        text: "Example:- example.atlassian.net",
      },
    ],
  },
  {
    title: "Project Query:",
    points: [
      {
        text: "To run queries in Jira, Query is written to filter out the data according to our requirements",
      },
      {
        text: "Example:- projectId ORDER BY created DESC",
      },
    ],
  },

  {
    title: "API Token:",
    points: [
      {
        text: "API tokens are used in place of traditional passwords to provide secure access to Jira. Here's how you can generate and use an API token in Jira:",
      },
      {
        text: "Here's how you can generate and use an API token in Jira:",
      },
    ],
  },
  {
    title: "Generating an API Token:",
    points: [
      {
        text: "Log in to Jira: Log in to your Jira account using your regular username and password.",
      },
      {
        text: "Click on your profile picture or username in the top right corner.",
      },
      {
        text: "Navigate to Your Profile Settings:",
      },
      {
        text: "Select Profile.",
      },
      {
        text: "Click on Manage Your Account.",
      },
      {
        text: "Then click on Security in header.",
      },
      {
        text: "Then Create and Manage API Tokens or similar button.",
      },
      {
        text: "Generate Token.",
      },
      {
        text: "Once generated, copy the API token to a secure location. This token will not be shown again, so make sure to keep it safe.",
      },
    ],
  },
  {
    title: "Issue Limit:",
    points: [
      {
        text: "Limit method is used to specify the maximum number of documents that should be returned in the result set of a query. It allows you to control the size of the result set, which can be especially useful when dealing with large collections or when you only need a subset of documents from a query.",
      },
    ],
  },
  {
    title: "Start at:",
    points: [
      {
        text: "Skip method is used to skip a specified number of documents in a collection and retrieve the documents that come after the skipped ones. It is often used in combination with the find method to implement pagination or to skip a certain number of documents that do not need to be included in the result set.",
      },
    ],
  },
];

export const sortingQueryHistory = [
  {
    value: -1,
    label: "Newest first",
  },
  {
    value: 1,
    label: "Oldest first",
  },
];

export const appStateConstant = {
  PROCESSING: "PROCESSING",
  READY: "READY",
};

export const accessTypes = [
  {
    value: "VIEWER",
    label: "Viewer",
  },
  {
    value: "EDITOR",
    label: "Editor",
  },
];

export const limitErrorMessage = "Please enter a value greater than 0";

export const editorThemeConfig = {
  ltr: "ltr",
  rtl: "rtl",
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  list: {
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
    listitem: "editor-listitem",
  },
};

export const pluginData = [
  { name: "Jira", logo: "/images/jira-plugin.svg" },
  { name: "Google Calendar", logo: "/images/google-calendar-plugin.svg" },
  { name: "Microsoft Calendar", logo: "/images/microsoft-calendar-plugin.svg" },
  { name: "Rest API Call", logo: "/images/rest-api-plugin.svg" },
];
export const MAX_TITLE_LENGTH = 150;

export const apiTypeOptions = [
  {
    name: "Custom",
    label: "CUSTOM",
    inputs: [
      {
        name: "API Key",
        label: "apiKey",
        type: "password",
        description: "Enter the API key of the selected model type."
      },
      {
        name: "Base URL",
        label: "baseURL",
        type: "text",
        description: "Enter the URL of the model to call the API."
      },
    ],
  },
  {
    name: "OpenAI",
    label: "OPENAI",
    inputs: [
      {
        name: "API Key",
        label: "apiKey",
        type: "password",
        description: "Enter the API key of the selected model type."
      },
      {
        name: "Base URL",
        label: "baseURL",
        type: "text",
        description: "Enter the URL of the model to call the API."
      },
    ],
  },
  {
    name: "Azure OpenAI",
    label: "Azure",
    inputs: [
      {
        name: "API Key",
        label: "apiKey",
        type: "password",
        description: "Enter the API key of the selected model type."
      },
      {
        name: "Base URL",
        label: "baseURL",
        type: "text",
        description: "Enter the URL of the model to call the API."
      },
    ],
  },
  {
    name: "Google",
    label: "Google",
    inputs: [
      {
        name: "API Key",
        label: "apiKey",
        type: "password",
        description: "Enter the API key of the selected model type."
      },
      {
        name: "Base URL",
        label: "baseURL",
        type: "text",
        description: "Enter the URL of the model to call the API."
      },
    ],
  },
  {
    name: "Groq",
    label: "GROQ",
    inputs: [
      {
        name: "API Key",
        label: "apiKey",
        type: "password",
        description: "Enter the API key of the selected model type."
      },
      {
        name: "Base URL",
        label: "baseURL",
        type: "text",
        description: "Enter the URL of the model to call the API."
      },
    ],
  },
  {
    name: "Bedrock Claude",
    label: "CLAUDE",
    inputs: [
      {
        name: "Access Key",
        label: "awsAccessKey",
        type: "text",
      },
      {
        name: "Secret Key",
        label: "awsSecretKey",
        type: "password",
      },
      {
        name: "Region",
        label: "awsRegion",
        type: "text",
      },
    ],
  },
];

export const packageTypes = {
  SUBSCRIPTION: "SUBSCRIPTION",
  ADDON: "ADDON",
};

export const serviceTypes = {
  ZBRAIN: "ZBRAIN",
  PIPEDRIVE: "PIPEDRIVE",
  HUBSPOT: "HUBSPOT",
};

export const fileStoreType = [
  {
    icon: "/images/file-store-icon.svg",
    heading: "ZBrain S3 Storage",
    description:
      "Utilize ZBrain S3 Storage for enhanced data management and precise results without additional token costs.",
    value: "ZBRAIN",
    upcoming: false,
  },
];

export const imageMethods = [
  {
    icon: "/images/ocr.svg",
    heading: "OCR",
    description:
      "This involves recognizing and extracting text content from images or documents to make it accessible for further processing or analysis.",
    value: "OCR",
  },
  {
    icon: "/images/analysis-image.svg",
    heading: "Analyze each page as an image using an LLM",
    description:
      "This treats each document page as an image, converts it to digital format, extracts text via OCR, and analyzes it with an LLM for insights or summaries.",
    value: "PAGE",
  },

  {
    icon: "/images/image-search.svg",
    heading:
      "Extract images from the document and then evaluate them using a LLM ",
    description:
      "This involves extracting images from a document and using an LLM to analyze their content, identifying objects, scenes, or patterns.",
    value: "IMAGES",
  },
];
export const shareWithTypes = [
  {
    icon: "/images/only-me-icon.svg",
    heading: "Custom",
    value: "CUSTOM",
  },
  {
    icon: "/images/custom-icon.svg",
    heading: "Everyone",
    value: "EVERYONE",
  },
  {
    icon: "/images/only-me-icon.svg",
    heading: "Only Me",
    value: "ONLY_ME",
  },
];

export const shareWithTypesKnowledgeBaseSetting = [
  {
    icon: "/images/custom-icon.svg",
    heading: "Everyone",
    value: "EVERYONE",
  },
  {
    icon: "/images/only-me-icon.svg",
    heading: "Only Me",
    value: "ONLY_ME",
  },
];

export const operatorTypes = [
  {
    icon: "/images/only-me-icon.svg",
    heading: "Custom",
    value: "CUSTOM",
  },
  {
    icon: "/images/custom-icon.svg",
    heading: "Everyone",
    value: "EVERYONE",
  },
];

export const documentTypeOptions = [
  { value: "book", label: "Book" },
  { value: "businessDocument", label: "Business Document" },
  { value: "paper", label: "Paper" },
  { value: "personalDocument", label: "Personal Document" },
  { value: "socialMediaPost", label: "Social Media Post" },
  { value: "webPage", label: "Web Page" },
];

export const languageOptions = [{ value: "english", label: "English" }];
export const categoryOptions = [
  { value: "technology", label: "Technology" },
  { value: "education", label: "Education" },
];

export const dataRefinementLabel = {
  automatic: `Set chunk and preprocessing rules automatically.
  Users unfamiliar with the process are recommended to
  select this option.`,
  custom: `Customize chunks rules, chunks length, and
  preprocessing rules, etc.`,
  pinecone: `Leverage the scalability of Pinecone directly within
  ZBrain for improved vector indexing and retrieval.`,
  economical: `Use ZBrain's cost-effective vector store with
  built-in vector engines and keyword indexes for
  efficient data handling.`,
  zbrainStorage: `Utilize ZBrain S3 Storage for enhanced data
  management and precise results without additional
  token costs.`,
  vectorSearch: `Inverted Index is a structure used for efficient
  retrieval. Organized by terms, each term points to
  documents or web pages containing it.`,
  fullTextSearch: `Index all terms in the document for users to search
  and retrieve relevant text chunks.`,
  hybridSearch: `Perform full-text and vector searches
  simultaneously, then prioritize the best match for
  the user's query through re-ranking. Configure the
  Rerank model API accordingly.`,
  newConnection: `Use your own vector store by providing the API key and credentials.`,
};

export const UpdateKBToggleMessage = {
  success: "Document status updated successfully",
  error: "Failed to update the document status",
};
export const billingSubMenu = [
  {
    name: "Overview",
    path: "/pricing/overview",
  },
  {
    name: "Billing history",
    path: "/pricing/history",
  },
  {
    name: "Credit Usage",
    path: "/pricing/credit-usage",
  },
  {
    name: "Usage Limit",
    path: "/pricing/usage-limit",
  },
];

export const RetrievalSettingToolTip = {
  topK: "Set this value to specify the number of most similar results you want to see for a query.",
  scoreThreshold:
    "Adjust this value to filter search results based on relevance.",
};

export const AppType = [{ value: "Chatbot", label: "Chatbot" }];

export const OrchestrationType = {
  flow: "ADVANCE",
  knowledgeBase: "BASIC",
  agents: "AGENT",
  app: "APP",
};
export const importStatus = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN-PROGRESS",
  STAGED: "STAGED",
  CREATED: "CREATED",
  ERRORED: "ERRORED",
};
export const filters = [
  { name: "Processing", key: "IN-PROGRESS" },
  { name: "Completed", key: "COMPLETED" },
  { name: "Failed", key: "ERRORED" },
  { name: "Pending", key: "PENDING" },
];

export const pageAsImageModels = [
  { value: "gpt-4o", label: "gpt-4o" },
  { value: "gpt-4-vision-preview", label: "gpt-4-vision-preview" },
  {
    value: "gpt-4-turbo",
    label: "gpt-4-turbo",
  },
  { value: "gpt-4-1106-vision-preview", label: "gpt-4-1106-vision-preview" },
];

export const appInstructions = `You are a friendly and polite customer service agent for ZBrain. Your primary responsibility is to assist users by answering their questions accurately and courteously. Always respond in the same language the question is asked, regardless of the language of the text in the context.

Behavior Guidelines:

Greetings:

Respond to greetings with appropriate and matching greetings.
Example: If a user says "Hello," respond with "Hello! How can I assist you today?"
Human-like Interaction:

Respond in a natural, conversational manner.
Avoid robotic or overly formal language.
Show empathy and support in your responses.
Acknowledge user concerns and assure them that you are there to help.
Contextual Relevance:

Tailor your responses to the context provided.
Ensure each response is relevant to the specific question and scenario.
Handling Uncertainty:

If you cannot deduce the answer from the context, say:
"I couldn't find a specific answer to your question. To assist you better, please provide additional details or rephrase your question. The more specific information you provide, the more accurate and helpful my response can be."
Attention Required Responses:

If any previous conversation contains the line "This answer requires attention," and the same question is asked again, provide a proper answer ensuring it's not identical to the previous one.
Visual Elements:

Do not generate or display visual elements in your responses.`;

export const automatedReasoningPrompt = `You are a highly intelligent assistant tasked with extracting structured information from documents. Your goal is to identify and organize variables present in the provided document into a specific format. Respond only with the JSON object and nothing else, so I can directly parse the answer into an object. Use the following instructions to structure your response:

Variables:

Identify all variables in the document.

For each variable, extract:

variable: The name of the variable in camelCase.

dataType: The datatype of the variable.

description: A clear explanation of what the variable represents.

Return all variables as an array of objects.

Output Format:

Variables: An array of objects, with each object containing variable, type, and description.

Example Output:

{
    "variables": [
      {
        "variable": "leaveOfAbsence",
        "dataType": "int",
        "description": "A type of leave where an employee is permitted to be absent from work for specific reasons."
      },
      {
        "variable": "paidLeave",
        "dataType": "str",
        "description": "A type of leave where the employee continues to receive their salary while on leave."
      }
    ]
  }

Please use this format to respond to user inputs and extract the necessary data from any given document.`

export const schemaStatusConstants = {
  PENDING: "PENDING",
  RUNNING: "RUNNING",
  COMPLETED: "COMPLETED",
  ERRORED: "ERRORED",
};

export const openAIBaseURL= "https://api.openai.com/v1/chat/completions"

export const slides = [
  {
    image: "/images/efficient-data.svg",
    heading: "Efficient Data Processing and Retrieval",
    description:
      "ZBrain ingests data from multiple sources and performs chunk-level optimization for efficient processing. It automatically discovers optimal retrieval strategies to enhance data access and retrieval.",
  },
  {
    image: "/images/build-workflows-new.svg",
    heading: "Effortless Creation of Business Logic Workflows",
    description:
      "Enables users to create complex business logic workflows for apps through its low-code interface, Flow. Users can integrate real-time data from various sources and use pre-built components for accelerated development.",
  },
  {
    image: "/images/refine-ai-new.svg",
    heading: "Refine AI with Human Feedback",
    description:
      "Collects feedback on AI outputs, enabling operators to correct and guide the AI for improved performance. Refines AI application outputs and optimizes retrieval strategies based on human feedback.",
  },
  {
    image: "/images/flexibility.svg",
    heading: "Flexibility with Any Model",
    description:
      "Seamlessly integrates proprietary models like GPT-4, Claude, and Gemini, as well as open-source models like Llama and Gemma, with the option for switching based on the user's needs.",
  },
];

export const workerStepNames = {
  OVERVIEW: "OVERVIEW",
  TEST_WORKER: "TEST_WORKER",
  FLOW: "FLOW",
};

export const fieldTypes = {
  TEXT: "TEXT",
  SHORT_TEXT: "SHORT_TEXT",
  LONG_TEXT: "LONG_TEXT",
  SECRET_TEXT: "SECRET_TEXT",
  NUMBER: "NUMBER",
  DATE: "DATE",
  DROPDOWN: "DROPDOWN",
  STATIC_DROPDOWN: "STATIC_DROPDOWN",
  CHECKBOX: "CHECKBOX",
  DYNAMIC: "DYNAMIC",
  CUSTOM_AUTH: "CUSTOM_AUTH",
  DATE_TIME: "DATE_TIME",
  FILE: "FILE",
  OAUTH2: "OAUTH2",
  ARRAY: "ARRAY",
  OBJECT: "OBJECT",
  BASIC_AUTH: "BASIC_AUTH",
  JSON: "JSON",
  MULTI_SELECT_DROPDOWN: "MULTI_SELECT_DROPDOWN",
  STATIC_MULTI_SELECT_DROPDOWN: "STATIC_MULTI_SELECT_DROPDOWN",

  // Components Field Types
  TRIGGER: "TRIGGER",
  KNOWLEDGE_BASE: "KNOWLEDGE_BASE",
  CO_WORKER: "CO_WORKER",
  VARIABLES: "VARIABLES",
  SUB_AGENT: "SUB_AGENT",
  APP: "APP",
  CONNECTIONS: "CONNECTIONS",
  OUTBOUND: "OUTBOUND",
};

export const statusMapping = {
  COMPLETED: "Completed",
  "IN-QUEUE": "Processing",
  "IN-PROGRESS": "Processing",
  ERRORED: "Failed",
  PENDING: "Pending",
};

export const recordStatus = {
  COMPLETED: "COMPLETED",
  ERRORED: "ERRORED",
  PENDING: "PENDING",
};

export const feedbackOptions = [
  "Don't like the style",
  "Not factually correct",
  "Response not updated",
  "Not following company's guidelines",
  "Others",
];

export const connectionsMap = [
  {
    name: "openai",
    icon: "/images/openai.svg",
  },
  {
    name: "dropbox",
    icon: "/images/dropbox.svg",
  },
  {
    name: "sheets",
    icon: "/images/google-sheets-logo.jpg",
  },
  {
    name: "drive",
    icon: "/images/google-drive-logo.svg",
  },
  {
    name: "docs",
    icon: "/images/google-docs-logo.png",
  },
];

export const AGENT_EMPTY_TEMPLATE = "empty-template";

// KB TOUR STEPS
export const stepsKbData = [
  {
    selector: ".step-1",
    title: "Select Knowledge Source",
    description:
      "Build your knowledge base by uploading documents directly in supported formats or importing data from your enterprise platforms.",
  },
  {
    selector: ".step-2",
    title: "Data Source Configuration",
    description:
      "Configure your selected data input to build the knowledge base. Name and describe your knowledge base, enable automated summaries, and customize settings based on data source type.",
  },
  {
    selector: ".step-3",
    title: "Text Data Refinement",
    description:
      "Refine the uploaded data for better searchability and usability. Set automatic or custom chunking and preprocessing rules and configure retrieval settings to optimize analysis and retrieval.",
  },
  {
    selector: ".step-4",
    title: "Execute and Finish",
    description:
      "Review the configured settings and click ‘Manage Knowledge Base’ to set up and manage the knowledge base.",
  },
];
// APP TOUR STEPS
export const stepsAppData = [
  {
    selector: ".step-1",
    title: "App Details",
    description:
      "Input necessary information for your app, including name, description, orchestration method, and additional details as needed.",
  },
  {
    selector: ".step-2",
    title: "Configure Bot",
    description:
      "Tailor your app's functionality by linking relevant resources—knowledge bases, flows, or agents—and configuring their behavior to meet your needs.",
  },
  {
    selector: ".step-3",
    title: "Appearance",
    description:
      "Personalize your app with a custom welcome message, name and description. Tailor the theme, logo, and icon to fit your preferences.",
  },
];

 // AGENT TOUR STEPS
 export const stepsAgentData = [
  {
    selector: ".step-1",
    title: "Agent Overview",
    description:
      "Name your agent and optionally add a description to establish its purpose and role within your workflow.",
  },
  {
    selector: ".step-2",
    title: "Create Queue",
    description:
      "Select and configure input sources to define what triggers the agent’s actions, like file uploads, emails, or messages.",
  },
  {
    selector: ".step-3",
    title: "Define Flow",
    description:
      "Define the tasks the agent will execute, from input processing to outcome generation, to map its logic and ensure it behaves as intended.",
  },
  {
    selector: ".step-4",
    title: "Additional Settings",
    description:
      "Choose from various output destinations, such as Gmail or Notion, to specify where the processed output will be directed. Additionally, configure the agent to pass its outputs to other agents.",
  },
  {
    selector: ".step-5",
    title: "Test Agent",
    description:
      "Validate the agent’s functionality by running test cases with sample inputs to verify its real-time output and ensure it meets your expectations.",
  },
];
export const knowledgeSectionToolTipContent = {
  uploadedDocumentsInDocsSection : "Access or manage all uploaded documents.",
  uploadDocument : "Drag and drop files directly into your knowledge base or browse your device to upload documents.", 
  importData : "Import data from various external platforms to enrich your knowledge base.",
  websiteURL : "Provide a website URL to source data for your knowledge base.",
  knowledgeBaseName : "Provide a unique name for your knowledge base for easy identification and access.",
  descriptionOptional : "Add a brief description to outline the purpose or scope of your knowledge base.",
  summarizeThisDocument : "Enable summarization to condense the imported document and provide context for the AI model.",
  selectModel : "Select an AI model that best fits your use case.",
  uploadedDocuments : "Set your preferred chunking and preprocessing rules for your documents.",
  custom : "Customize chunk rules, chunk length, preprocessing rules etc.",
  embeddingType : "Select the embedding type to optimize text representation for your use case.",
  retrievalSettings : "Configure how information is retrieved from your data sources.",
  topK : "Set the maximum number of relevant results returned for a user's search query.",
  scoreThreshold : "Set the minimum score required for a result to be considered relevant and included in the output.",
  VectorStore : "Choose a vector store to manage the vector data, enabling efficient indexing and retrieval of relevant information.", 
  segmentationRule : "The chunking and preprocessing rules set previously for segmenting and processing text data.",
  selectVectorStore : "The selected vector store where your data is stored and indexed for efficient retrieval.",
  chunksLength : "View the maximum length set for each chunk of text to ensure efficient processing and storage.",
  textPreDefinition : "Specifies any initial processing rules applied to the text data.",
  retrievalSection : "Presents relevant excerpts from a knowledge base specifically addressing the user's query.",
  matchingStore : "A minimum matching score to filter search results by relevance.",
  history : "Logs and displays previous queries and interactions",
  knowledgeVariables : "ZBrain retrieves variables from knowledge bases, each with its ID, type, and description, representing a specific entity confined to a single data format.",
  extractedRules : "A set of predefined conditions, logic or definite statements/rules retrieved from the selected knowledge base.",
  variableName : "Name of any additional variable to be included in the knowledge variable list.",
  variableType : "Valid type of variable to be included in the knowledge variable list such as <str>,<float>,<bool> or any other type.",
  description : "A brief description of the variable to be included in the knowledge variable list.",
  addRule : "Any valid predefined condition, rule or logic to be included in the Extracted Rules list.",
  playground : "A development environment where users can experiment with and test various rules and variables within the knowledge base.",
  test : "Run specific questions and evaluate responses based on the automated reasoning of the model.",
  historyValidation : "Logs and displays previous queries and validation results.",
  findings : "Displays the model's response generated through automated reasoning, along with a summary of the rules and variables extracted for the user's query.",
  appliedRules : "Displays relevant conditions, rules, and logic retrieved from the selected knowledge base in response to the query.",
  extractedVariables : "Knowledge source variables specific to the user’s query, along with their IDs, types, and descriptions.",
  shareWith : "Set access permission to keep the knowledge base private or open to everyone.",
  vectorStore : "Displays the selected vector store for efficient storage, indexing and retrieval of data.",
  fileStore : "Displays the selected file storage for efficient data management and analytics.",
  retrievalSetting : "The selected document retrieval setting indexes, searches, and retrieves relevant content based on predefined configurations."
  
}

export const appToolTip = {
  appType : "Select the type of application you wish to create, such as a Chatbot.",
  appAccessType : "Specify who can access your app by selecting a public or private access type.",
  orchestrationMethod : "Select how you want to create the app: connect to a Knowledge Base, Flow, or Agent.",
  appName : "Provide a name for your app that reflects its purpose or functionality.",
  descriptionOptional : "Provide a brief description to outline the app’s features and intended use.",
  knowledgeBase : "Select one or more knowledge bases you want to connect.",
  name : "Displays the name(s) of the connected knowledge base(s) for reference.",
  schema : "Displays schema availability for the connected knowledge base.",
  instructions : "Provide guidelines and additional information to assist the bot in delivering accurate responses. ",
  settings: "Adjust advanced settings to tailor your app's configuration.",
  chunkingStrategy : "Allow the model to divide text into manageable parts for efficient processing.",
  welcomeMessage : "Craft a greeting message that users will see upon initiating a chat with the app.",
  appNameAppearance : "Assign a unique name to your app that reflects its purpose or theme.",
  appDescriptionAppearance : "Provide a brief overview of your app's functionality and the value it delivers.",
  sampleQuestions : "List example questions that guide users on how to interact with the app effectively.",
  appLogoAppearance : "Upload a logo to visually represent your app. ",
  appThemeAppearance : "Choose from the provided themes to customize the visual appearance of your app.",
  botNameAppearace : "Assign your bot a name.",
  botIconAppearace : "Customize your bot’s icon to fit your preferences.",
  testQuestions : "Add test questions and answers to evaluate and refine your bot's performance.",
  settingsApp : "Adjust advanced settings to tailor your app's configuration.",
  autoConfigure : "Auto-configure the best settings for your app; view configuration accuracy under Bot Performance.",
  manualConfigure : "Advanced users can customize settings manually.",
  appLink : "Share this link to provide external access to your app.",
  widgetIntegration : "Insert this script into your website to integrate the conversational widget. ",
  collectUserInfo : "Activate to request personal details from users before engaging with the bot",
  testQuestion : "Displays a sample question ",
  correctAnswer : "Lists the expected correct response provided by the user to the test question.",
  zbrainAnswer : "Presents the actual response provided by the bot for comparison.",
  accuracy : "Indicates the accuracy percentage of the bot's response to the test question. ",
  versionSetting : "Specific instructions and configuration settings for the bot’s current version."

}

export const agentToolTips = {
  agentName : "Enter a unique name for your AI agent to identify its purpose easily.",
  agentDescription : "Provide a description to explain the agent's function or use case.",
  test : "Upload a document to test the agent's performance and accuracy.",
  queue : "View and manage previous requests processed by the agent.",
  input : "Input data processed by the agent.",
  agentActivity : "Track and monitor the actions or processes performed by the agent.",
  report : "View the detailed report generated by the agent.",
  agentAccessType : "Select the access type for the agent: Public gives open access, while Private restricts access to invited operators.",
  uploadDocument  : "View the uploaded document.",
}

export const  subHeadingContent = {
  dataSourceConfiguration : "Configure your selected data input to build the knowledge base. Name and describe your knowledge base, enable automated summaries, and customize settings based on data source type.",
  selectKnowledgeBaseSource : "Select the data source for your knowledge base.",
  textDataRefinement : "Ensure your documents are structured and optimized for better searchability and seamless data retrieval.",
  executeAndFinish : "Complete the setup to build your knowledge base. Once built, access and manage your knowledge base with ease.",
  automatedReasoning : "Enables extracting and defining knowledge variables and rules to facilitate their testing in an interactive playground.",
  appearance : "Personalize your app with a custom welcome message, name and description. Tailor the theme, logo, and icon to fit your preferences.",
  createNewApp : "Create a new app by specifying its type, access level, and orchestration method to match your needs. ",
  configureBot : "Customize bot settings by integrating the chosen orchestration method and defining any additional parameters.",
  agentOverview : "Define the agent’s name and description to outline its purpose and functionality.",
  createQueue : "Set up input sources that trigger the agent’s actions, such as receiving files, emails, or messages, to start the task sequence.",
  defineFlow : "Configure the agent’s workflow by determining the order of tasks it will execute, integrating programming logic, helper methods and third-party tools. ",
  additionalSettings : "Customize your agent by choosing output destinations such as Google Sheets or SendGrid and enable passing outputs to other agents for more advanced workflows.",

}
