import { manageWorkersConstants } from "../../constants";
const { ACTION_TYPES } = manageWorkersConstants;

const {
  UDPATE_STATE,
  EXAMPLE_ACTION_TYPE_ONE,
  EXAMPLE_ACTION_TYPE_TWO,
  UPDATE_PUBLIC_AGENT,
} = ACTION_TYPES;

export const initialState = {
  workers: { data: [], total: 0 },
  isLoading: true,
  isPublicAgent: false,
};

export default function manageWorker(state = initialState, action) {
  switch (action.type) {
    case UDPATE_STATE:
      return { ...state, ...action.payload };
    case UPDATE_PUBLIC_AGENT:
      return { ...state, ...action.payload };
    case "UPDATE_AGENT":
      return {
        ...state,
        workers: {
          ...state.workers,
          data: action.payload,
        },
      };
    case EXAMPLE_ACTION_TYPE_ONE:
      break;
    case EXAMPLE_ACTION_TYPE_TWO:
      break;
    default:
      return state;
  }
}
